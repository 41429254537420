import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Wourdle is the game you already know, but preferring British English spelling. And because the Brits love nothing more than making things difficult for themselves, words are 6 letters long.
      </p>
      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 7 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="C"
          status="correct"
        />
        <Cell value="O" isCompleted={true} />
        <Cell value="L" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
        <Cell value="U" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter C is in the word and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="C" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="T" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter N is in the word but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="B" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="O" status="absent" />
        <Cell value="U" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter O is not in the word in any spot.
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        Wourdle is based on <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
        >
          react-wordle by Chase Wackerfuss
        </a>. Special thanks to Ghent University's Center for Reading Research for their extensive British word lexicon.
      </p>
    </BaseModal>
  )
}
